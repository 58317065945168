import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilSpeedometer, cilLibraryBuilding, cilAudioSpectrum, cilTask, cilPuzzle, cilPenNib, cibGreenkeeper, cibMagento } from '@coreui/icons'
import { CNavItem, CNavGroup } from '@coreui/react'

const _nav = [
  // {
  //   component: CNavItem,
  //   name: 'Dashboard',
  //   to: '/pages/dashboard',
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Dashboard',
    dbname: 'dashboard',
    to: '/pages/analytics',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,

  },
  {
    component: CNavItem,
    name: 'Role Base Access',
    dbname: 'roleBaseAccess',
    to: '/pages/RoleHome',
    icon: <CIcon icon={cibGreenkeeper} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Home Management',
  //   dbname: 'homeManagement',
  //   to: '/pages/home',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />
  // },
  {
    component: CNavItem,
    name: 'Master',
    dbname: 'master',
    to: '/pages/master/institute',
    icon: <CIcon icon={cibMagento} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavGroup,
  //   name: 'Mentor',
  //   dbname: 'mentor',
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Mentor Applications',
  //       to: '/pages/mentor',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Scheduled Meetings',
  //       to: '/pages/allschedulemeetings',
  //     },
  //   ],
  // },
  {
    component: CNavItem,
    name: 'Teacher Master',
    dbname: 'teacherMaster',
    to: '/pages/teacherMaster',
    icon: <CIcon icon={cilPenNib} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Campus Wall',
  //   dbname: 'campusWall',
  //   to: '/pages/campusWall',
  //   icon: <CIcon icon={cilMobile} customClassName="nav-icon" />,
  // },

  // {
  //   component: CNavItem,
  //   name: 'Notifications',
  //   dbname: 'notifications',
  //   to: '/pages/notifications',
  //   icon: <CIcon icon={cilBell} customClassName="nav-icon" />
  // },
  // {
  //   component: CNavItem,
  //   name: 'College Referrals',
  //   dbname: 'collegeReferrals',
  //   to: '/pages/collegeReferrals',
  //   icon: <CIcon icon={cilSoccer} customClassName="nav-icon" />
  // },
  // {
  //   component: CNavItem,
  //   name: 'Reports',
  //   dbname: 'reports',
  //   to: '/pages/reports',
  //   icon: <CIcon icon={cilReportSlash} customClassName="nav-icon" />
  // },
  {
    component: CNavItem,
    name: 'Payment Details',
    dbname: 'payment',
    to: '/pages/payment-details',
    icon: <CIcon icon={cilTask} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Offline Payment',
    dbname: 'offlinePayment',
    to: '/pages/offline-payment',
    icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Enrolled Free Courses',
    dbname: 'enrolledFreeCourses',
    to: '/pages/enrolled-free-courses',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Analytics Details',
    dbname: 'analytics',
    to: '/pages/analytics-details',
    icon: <CIcon icon={cilAudioSpectrum} customClassName="nav-icon" />
  }
]
export default _nav
