import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Page404 from "./page404/Page404";
import { AppSidebar, AppHeader, AppFooter } from "src/components";
import { isAuthenticated } from '../../utils/auth_service'
import Notification from "./Notifications/Notification";
import Home from "./Home_Management/Home";
import MasterRoute from "./Master/_masterRoute";
import Mentor_Program from "./Mentor/Mentor_Program";
import AllScheduleMeetings from "./Mentor/AllScheduleMeetings";
import analytics_routes from "./Analytics/analytic_routes";
import CollegeReferral from "./CollegeReferral/CollegeReferral";
import RoleHome from "./RBack/RoleHome";
import secureLocalStorage from "react-secure-storage";
import Teacher from "./Teacher/Teacher";
import CampussWall from "./CampussWall/CampussWall";
import Reports from "./Reports/Reports";
import Payment from "./Payment/Payment";
import OfflinePayment from "./OfflinePayment/OfflinePayment";
import OverAllAnalytics from "./OverAllAnalytics/OverAllAnalytics";
import enrolledFreeCourses from "./EnrolledFreeCourses/EnrolledFreeCourses";

const PagesIndex = () => {
    let token = localStorage.getItem("token")
    if (token !== null) {
        let userRoles = localStorage.getItem("userRoles")
        let userRolesArray = userRoles.split(',');
        return (
            <div>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <AppHeader />
                    <div className="body flex-grow-1 px-3">
                        <Route
                            path="/pages"
                            render={({ match: { url } }) =>
                            (
                                <Switch>
                                    {userRolesArray.some(data => data == 'dashboard') && <Route path={`${url}/`} component={analytics_routes} exact />}
                                    {userRolesArray.some(data => data == 'dashboard') && <Route path={`${url}/analytics`} component={analytics_routes} />}
                                    {userRolesArray.some(data => data == 'roleBaseAccess') && <Route path={`${url}/rolehome`} component={RoleHome} />}
                                    {/* {userRolesArray.some(data => data == 'homeManagement') && <Route path={`${url}/home`} component={Home} exact />} */}
                                    {userRolesArray.some(data => data == 'master') && <Route path={`${url}/master`} component={MasterRoute} />}
                                    {/* {userRolesArray.some(data => data == 'mentor') && <Route path={`${url}/mentor`} component={Mentor_Program} exact />}
                                    {userRolesArray.some(data => data == 'mentor') && <Route path={`${url}/allschedulemeetings`} component={AllScheduleMeetings} />} */}
                                    {userRolesArray.some(data => data == 'teacherMaster') && <Route path={`${url}/teacherMaster`} component={Teacher} />}
                                    {/* {userRolesArray.some(data => data == 'campusWall') && <Route path={`${url}/campusWall`} component={CampussWall} exact />} */}
                                    {/* {userRolesArray.some(data => data == 'notifications') && <Route path={`${url}/notifications`} component={Notification} exact />} */}
                                    {userRolesArray.some(data => data == 'payment') && <Route path={`${url}/payment-details`} component={Payment} exact />}
                                    {userRolesArray.some(data => data == 'offlinePayment') && <Route path={`${url}/offline-payment`} component={OfflinePayment} exact />}
                                    {userRolesArray.some(data => data == 'enrolledFreeCourses') && <Route path={`${url}/enrolled-free-courses`} component={enrolledFreeCourses} exact />}
                                    {userRolesArray.some(data => data == 'analytics') && <Route path={`${url}/analytics-details`} component={OverAllAnalytics} exact />}
                                    {/* {userRolesArray.some(data => data == 'collegeReferrals') && <Route path={`${url}/collegeReferrals`} component={CollegeReferral} />} */}
                                    {/* {userRolesArray.some(data => data == 'reports') && <Route path={`${url}/reports`} component={Reports} />} */}
                                    <Route path={`${url}/*`} component={Page404} />
                                </Switch>
                            )
                            }
                        />
                    </div>
                    <AppFooter />
                </div>
            </div>
        )
    } else {
        return <Redirect to='/' />
    }
}

export default PagesIndex;