import React, { useState, useEffect } from "react";
import { CRow, CCol, CCard, CCardBody, CButton, CCardTitle, CAvatar, CFormInput, CFormSelect, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react/dist/index.js';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import userApi from 'src/utils/axios';
import ConfigData from '../../../config/constant.json';
import LoadingScreen from "src/views/shared/Loading";
import OverAllAnalytics from "./OverAllAnalytics";
import VideoAnalyticsData from "./VideoAnalytics";

const AnalyticsData = ({ courseId, batchId, courseName, batchName }) => {

    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [error, setError] = useState(false);
    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0);
    const [loader, setLoader] = useState(true);
    const itemsPerPage = 10;
    const [filterBy, setFilterBy] = useState('');
    const [view, setView] = useState(false);
    const [backView, setBackView] = useState(false);
    const [userId, setUserId] = useState('');

    useEffect(() => {
        if (debouncedValue !== '') {
            getBatchWiseAnalyticsData(debouncedValue);
        } else {
            getBatchWiseAnalyticsData('');
        }

    }, [debouncedValue, offset, filterBy]);

    const getBatchWiseAnalyticsData = async (searchTerm) => {

        try {
            setLoader(true);

            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/user/getAnalyticsDataByBatch?batchId=${batchId}&courseId=${courseId}&searchTerm=${searchTerm}&offset=${offset}&filterBy=${filterBy}`);

            if (response.data) {
                setUsers(response.data?.data?.data);
                setPageCount(Math.ceil(response.data?.data?.dataCount / itemsPerPage));
                setCount(response.data?.data?.dataCount)
            }
            setLoader(false); // Set loader to false after data fetching
        } catch (error) {
            // setError(`${error.message} :(`)
            setLoader(false) // Set loader to false on error     
        }
    }

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    // Handle pagination click
    const handlePageClick = (e) => {
        setOffset(e.selected)
    }

    // Handle filter by student type change
    const filterByStudent = (e) => {
        setFilterBy(e.target.value);
    }

    const goBack = () => {
        setBackView(true);
        setView(false);
    }

    const viewAnalytics = (userId) => {
        setUserId(userId)
        setView(true);
        setBackView(false);
    }

    return (
        <> {
            !view && !backView &&
            <>

                <CRow>
                    <CCol sm={12} className='pb-4'>
                        <CCard style={{ background: "#fff" }}>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={8}>
                                        <CCardTitle style={{ color: "#000" }}>{`${courseName} (${batchName})`}</CCardTitle>
                                    </CCol>
                                    <CCol sm={3}>
                                    </CCol>
                                    <CCol sm={1}>
                                        <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => goBack()}>Back</CButton>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>

                <CRow>
                    <CCol md={6} style={{ paddingLeft: '30px' }}>

                        <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                            <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                <CIcon icon={cilSearch} size="xl" />
                            </div>

                            <CFormInput
                                placeholder="Search..."
                                autoComplete="Search"
                                style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                onChange={handleSearch}
                            />
                        </div>
                    </CCol>
                    <CCol md={2}></CCol>
                    <CCol md={4} style={{ paddingRight: '50px' }}>
                        <CFormSelect aria-label="Default select example" onChange={filterByStudent}>
                            <option disabled selected="true">Filter By</option>
                            <option value="">All</option>
                            <option value="InHouse">InHouse</option>
                            <option value="OutSider">OutSider</option>
                            <option value="TEACHER">Teacher</option>
                        </CFormSelect>
                    </CCol>
                </CRow >

                {
                    loader ?
                        <LoadingScreen />
                        :
                        <>
                            <h5 style={{ marginBottom: '1%', marginLeft: '2%' }}>Total Count: <span ><b>{count}</b></span></h5>
                            <div style={{ marginLeft: '2%', marginRight: '2%' }}>
                                <Table striped bordered hover id='user-table'>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Student Name</th>
                                            <th>Email</th>
                                            <th>Student Type</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.length > 0 &&
                                            users.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{offset * 10 + (index + 1)}</td>
                                                    <td>{item.user[0]?.fullName ? item.user[0]?.fullName : (item.user[0]?.firstName ? `${item.user[0]?.firstName} ${item.user[0]?.lastName}` : item.user[0]?.name)}</td>
                                                    <td>{item.user[0]?.email}</td>
                                                    <td>{item.user[0]?.roleType === 'TEACHER' ? 'Teacher' : (item.user[0]?.registration === true ? 'Outsider' : 'InHouse')}</td>
                                                    <td style={{ textAlign: 'center' }}>{item.paymentMode === 'FREE' ? <CAvatar color="info" style={{ width: '50px', padding: '2px' }} shape="rounded" textColor="white">FREE</CAvatar>
                                                        : <CAvatar style={{ width: '50px', padding: '2px' }} color="success" shape="rounded" textColor="white">PAID</CAvatar>}</td>
                                                    <td><CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => { viewAnalytics(item.user[0]?.userId) }}>View</CButton></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>

                                {/* Pagination Component */}
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={(event) => { handlePageClick(event) }}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    forcePage={offset}
                                />
                            </div>
                        </>
                }

            </>
        }
            {
                view && !backView &&
                <>
                    <VideoAnalyticsData courseId={courseId} batchId={batchId} batchName={batchName} courseName={courseName} userId={userId} />
                </>
            }

            {
                !view && backView &&
                <>
                    <OverAllAnalytics />
                </>
            }
        </>
    );

}

export default AnalyticsData;